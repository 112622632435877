.head_head {
  background-color: white;
  position: fixed;
  width: 85.5%;
  margin-left: 280px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.head_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px;
}

.head_img {
  font-size: 30px;
}

.head_img_m {
  font-size: 20px;
}

.msg {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.dropdwn_msg {
  background-color: white;
  margin-top: 24px;
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.dropdwn_msg_1 {
  background-color: white;
  margin-top: 30px;
  position: absolute;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.falcon-logo {
width: 183px;

}
.dropdown-log{
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}


.avatar.avatar-md .avatar-content,
.avatar.avatar-md img {
  font-size: 0.8rem;
  height: 32px;
  width: 32px;
}

.dropdown_logout {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


}

.logout_ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.logout_li {
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

/* Header.css */
.head_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.head_right .msg {
  margin-right: 20px;
  position: relative;
}

.head_img_m, .head_img {
  cursor: pointer;
}

.head_img_m {
  width: 20px;
  height: 20px;
}

.head_img {
  width: 25px;
  height: 25px;
}

.user-img {
  display: flex;
  align-items: center;
}

.avatar {
  position: relative;
  cursor: pointer;
}

.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.dropdown_logout {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width: 150px;
}

.logout_ul {
  list-style-type: none;
  padding: 0;
}

.logout_li {
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 1450px) {
  .head_head {
      width: 84%;
  }
}

@media (max-width: 768px) {
  .head_head {
    justify-content: space-between;
  }

  .head_right {
    flex-wrap: wrap;
  }

  .user-img {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .msg {
    margin: 5px;
  }

  .dropdwn_msg {
    margin-top: 10px;
  }

  .dropdwn_msg_1 {
    margin-top: 10px;
  }

  .head_right > div {
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media(max-width:1848px){
  .head_head {
    width: 80%;
  }
}

@media(max-width:1319px){
  .head_head {
    width: 78%;
  }
  .falcon-logo{
    width:119px
  }
}
@media (min-width: 768px) {


  .navbar-nav {
    gap: 10px; /* Example gap between menu items */
  }

  .user-menu {
    cursor: pointer; /* Example cursor style for user menu */
  }

  .dropdown-menu {
    min-width: 200px; /* Example minimum width for dropdown menu */
  }
  .custom-dropdown{
    margin-right: 15px;
  }

}
@media(max-width:1742px){
  .head_head {
    width: 84%;
    margin-left: 17.5%;
    height: 6%;
  }
} 

