.search_1{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}
.tttt{
    display: flex;
    align-items: center;
    gap: 20px;
}