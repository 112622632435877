table .thead-light th {
  color: var(--title-color);
  background-color: #f7faff;
  border-color: rgba(1, 119, 205, 0.1);
}

.table .thead-light th {
  color: #677788;
  background-color: #f8fafd;
  border-color: rgba(231, 234, 243, 0.7);
}

.card {
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 5px;
}


.sub-btn-print {
  background-color: #007bff; /* Primary button color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.sub-btn-print:hover {
  background-color: #0056b3; /* Darker shade for hover */
}

.close-btn {
  background-color: #dc3545; /* Danger color for the close button */
}

.close-btn:hover {
  background-color: #c82333;
}

.m-2 {
  margin: 8px; /* Adjust margin as needed */
}


.card-body {
  padding: 1rem;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.gap-3 {
  gap: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}
.rounded-circle {
  border-radius: 50% !important;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.3125rem;
}
.avatar-60 {
  width: 60px !important;
  min-width: 60px !important;
  height: 60px !important;
}

.avatar-70 {
  width: 70px !important;
  min-width: 70px !important;
  height: 70px !important;
}

/* Styles for the toggle button */
.switcher_input {
  display: none;
}

.switcher_label {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
  background-color: #ccc;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switcher_label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switcher_input:checked + .switcher_label {
  background-color: #4caf50;
}

.switcher_input:checked + .switcher_label::after {
  transform: translateX(25px);
}
.icon-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd;
}

.user-icon {
  font-size: 14px;
  color: #000;
}

.img_con-loc {
  inline-size: 30px;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.title-color {
  color: var(--title-color);
}
.badge-soft-success {
  color: #07b275;
  border-radius: 5px;
  border: 1px solid rgba(7, 178, 117, 0.2);
  background: #effff9;
}
.badge-soft-warning {
  color: #fe961c;
  border-radius: 5px;
  border: 1px solid rgba(254, 150, 28, 0.2);
  background: #fef8f2;
}
.badge-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
.badge-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}
.status-badge {
  margin-left: 10px;
}
.text-danger {
  color: #ed4c78 !important;
  font-weight: 500;
}



.flex-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

.falcon-logo {
  margin-right: 20px; /* Add some space between the image and the content */
}

.content_order {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Modal {
  background-color: #fffefe;
  margin: 100px;
  height: 100vh;
  padding-bottom: 100px;
}

.total-price-card {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: auto;
}

.total-price-card p {
  margin: 0;
}
.width-test {
  width: 40%;
}
table {
  margin-top: 0px !important;
}

.height-print {
}
