.form-fm{
  display: flex;
 justify-content: space-between;
  align-items: center;

}
.ffff{
  display: flex;
  align-items: center;
  gap: 20px;
}