.cat-left_1{
    display: flex;
    justify-content: space-between;
}
.form_1{
    display: flex;
    flex-direction: column;
}
.form_0{
    display: flex;
    justify-content: space-between;
    padding-top: 20PX;
}
.form_2{
    display: flex;
    gap: 460px;
    padding-top: 20PX;
}
.btn_vars{
    display: flex;
    gap: 30px;
    padding-top: 20PX;
}