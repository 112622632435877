.search-left-pro {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-content {
  display: flex;
  align-items: center;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 5px;
}

.searchbar {
  display: flex;
  align-items: center;
}

.btn-clear {
  margin-left: 10px;
  border: none;
  background-color: rgb(248, 7, 7);
  color: #fff;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
}
.export-btn{
  color: rgb(29, 111, 66);
}
.export {
  color: #158dfd;
  background-color: transparent;
  border: none;
}