.cat-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cat-left h3 {
  text-align: right;
}

.modal .modal-header .close {
  background: none;
  border: none;
  border-radius: 50%;
  padding: 7px 10px;
}
.formswitch {
  width: 100px;
}
.status-label {
  white-space: nowrap; 
}
.form-check-input {
  height: 25px;
  width: 50px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: grey;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: blue;
}

input:focus + .slider {
  box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7);
  outline: none;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#table-to-xls {
  width: 100%;
  border-collapse: collapse;
}

#table-to-xls th,
#table-to-xls td {
  border: 1px solid #ddd;

  text-align: left;
}

#table-to-xls th {
  background-color: #f2f2f2;
}
.active-item {
  align-items: center;
  background-color: #078f30;
  border-radius: 5px;
  color: #fff;
  /* display: flex; */
  height: 25px;
  justify-content: center;
  padding: 7px 23px;

  width: 100px;
}

.inactive-item {
  align-items: center;
  background-color: rgb(206, 4, 4);
  border-radius: 5px;
  color: #fff;
  /* display: flex; */
  height: 25px;
  justify-content: center;
  padding: 7px 17px;

  width: 100px;
}

.main-container {
  background-color: #f9f9fb;
  width: 100%;
  padding: 40px;
  overflow: auto;
}

.card-cat {
  box-shadow: 0px 0px 10px 0px #d3d0d0;
  padding: 30px;
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  overflow: auto;
  height: 75vh;
}

table {
  border: 1px solid #a1a1a1;
  border-collapse: collapse;
  width: 100%;
}
.table-container {
  max-height: 600px;
  overflow-y: auto;
}

th,
td {
  padding: 0px 10px;
  text-align: left;
  border-bottom: 1px solid #a1a1a1;
  border-right: 1px solid #a1a1a1;
  color: #474747;
  border: 1px solid #f2f2f2;
  padding: 0px 10px;
  text-align: left;
  border-bottom: 1px solid #f2f2f2;
}

th {
  background-color: #f0f0f0;
  font-weight: bold;
  color: #000;
  padding: 0px 10px !important;
}
td {
  height: 5px;
}
.status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  font-weight: bold;
}

.status-active {
  background-color: green;
}

.status-inactive {
  background-color: red;
}

.view,
.edit,
.delete {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.view {
  background-color: #007bff;
  color: white;
}

.edit {
  background-color: #ffc107;
}

.delete {
  background-color: #dc3545;
  color: white;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.head-fon-mv {
  text-align: right;
}

.breadcrumb-item a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
  cursor: pointer;
}

.breadcrumb-item a:hover {
  color: #228bfc;
}

.searchbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid #e3e0e0;
}

.search-icon {
  color: #474747;
  cursor: pointer;
}

.search {
  border: none;
  outline: none;
  width: 100%;
  padding: 5px;
  color: #000;
  background-color: transparent;
  border: none;
}

.search-left {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.dataTables_paginate {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  margin: 0;
  list-style: none;
}

.pagination li {
  display: inline-block;
  margin: 0;
}

.pagination button {
  cursor: pointer;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px; /* Rounded switch */
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%; /* Rounded handle */
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
