/* Manageproduct.css */

/* Modal Content Styles */
.variant-details {
    margin-bottom: 20px;
  }
  
  .variant-details p {
    margin-bottom: 5px;
  }
  
  .btn-view {
    border: none;
    background-color: transparent; /* Optional: Makes the button background transparent */
    padding: 0; /* Optional: Removes any default padding */
  }
  
  
  .variant-image {
    width: 100px;
    height: 100px;
    padding: 5px;
    margin: 5px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  
  