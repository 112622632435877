@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  font-family: "Open Sans", sans-serif;

  font-weight: 400; 
  line-height: 1.6;
  color: #677788;
  text-align: left;
  font-family: var(--font);
}


.add-category-button {
  background-color: #399ee0;
  color: white;
  padding: 8px 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 8px;
}

.add-category-button:hover {
  background-color: #2e9ee9;
}

.btn-edit,
.btn-delete {
  border: none;
  background-color: transparent;
}

/* Additional styles for the "Create" button */
.create-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-button:hover {
  background-color: #45a049;
}


.form-control:focus{
  box-shadow: none !important;
  border: 1px solid black !important;
}
.form-select:focus{
  box-shadow: none !important;
  border: 1px solid black !important;
}

/* .modal .modal-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0px #7f7a7a;
}

.modal .modal-header .close:hover {
  background: rgb(233, 227, 227);
} */