.Order-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.main-container_1 {
  background-color: aliceblue;
  width: 100%;
  padding: 40px;
  padding-top: 100px;
  overflow: auto;
}
.latest {
  display: flex;
  padding-top: 20px;
}
.l_3 {
  display: flex;
  justify-content: space-between;
}
.l_3_1 {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.calendar {
  border: 1px solid #b3b9bd;
  padding: 7px 54px;
  border-radius: 5px;
}
.Search {
  padding: 7px 7px;
  border-radius: 5px;
  border: 1px solid #b3b9bd;
}
.refresh {
  font-size: 24px;
  cursor: pointer;
}
.refresh_0 {
  padding: 10px;
}
.od_items {
  display: flex;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
}
.o_btn {
  background-color: #2e9ee9;
  padding: 10px;
  border: none;
  color: white;
  border-radius: 5px;
}
.oo_btn {
  padding: 10px;
  border: none;

  border-radius: 5px;
}
.table {
  padding-top: 20px;
}
.clear_btn {
  display: flex;
  gap: 20px;
}
.order-10 {
  display: flex;
  justify-content: space-between;
}
.order-1 {
  padding: 20px;
}
.placed {
  display: flex;
  gap: 20px;
}
.subtotal {
  display: flex;
  gap: 30px;
}
.main-subtotal {
  display: flex;
  flex-direction: column;
  margin-left: 755px;
  margin-top: 28px;
  /* justify-content: flex-end; */
}

/*  */
.sum-total-out {
  width: 371px;
  margin-top: 29px;
}
.Acc-reject {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}
.card-dash-22 {
  width: 530px;
  /* height: 550px; */
  background-color: #ffff;
  border-radius: 10px;
  margin-left: 30px;
  padding: 20px;
  /* margin: 10px; */
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.card-dash-21 {
  width: 900px;
  /* height: 550px; */
  background-color: #ffff;
  border-radius: 10px;
  margin-left: 30px;
  padding: 20px;
  /* margin: 10px; */
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.card-dash-222 {
  width: 400px;
  /* height: 550px; */
  background-color: #ffff;
  border-radius: 10px;
  margin-left: 30px;
  padding: 20px;
  /* margin: 10px; */
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.it-summary {
  display: flex;
  margin-top: 14px;
  border-bottom: 1px solid rgb(221, 211, 211);
}
.sum-per {
  width: 10%;
}
.sum-per-1 {
  width: 15%;
}
.sum-per-11 {
  width: 18%;
}
.cao {
  border-bottom: 1px solid rgb(221, 211, 211);
}
.phone {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  border-bottom: 1px solid rgb(221, 211, 211);
}
.main-cd {
  display: flex;
}
.phone-1 {
  display: flex;
  justify-content: space-between;
}
/* DateRangePicker.css */
.date-range-picker {
  width: 100%;
  max-width: 300px;
}

.date-range-picker__wrapper {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-range-picker__calendar-icon {
  color: #007bff;
}

.date-range-picker__inputGroup {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.date-range-picker__inputGroup__input {
  border: none;
  outline: none;
  font-size: 1rem;
  text-align: center;
}

.date-range-picker__clear-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.date-range-picker__clear-button:hover {
  color: #0056b3;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-color: inherit;
}
.table-responsive thead {
  position: sticky;
  top: 0;

}
tr {
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
  border-color: inherit;
}
.table .thead-light th {
  color: var(--title-color);
  background-color: #f7faff;
  border-color: rgba(1, 119, 205, 0.1);
}
thead.text-capitalize th {
  text-transform: capitalize;
  font-size: 14px;
}