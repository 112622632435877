.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.3125rem;
}

.heading_img {
  position: absolute;
  inset-block-start: 16px;
  inset-inline-end: 16px;
}

.business-analytics {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 30px 20px;
  position: relative;
  border: 1px solid rgba(180, 208, 224, 0.5);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  height: 100%;
  transition: all 0.3s ease;
  cursor: pointer;
}
.date-container input[readonly] {
  background-color: #f8f9fa; /* Optional: Lighten the background */
  cursor: pointer;
}
.date-container input {
  pointer-events: none; /* Disable text input */
  background-color: #f8f9fa; /* Optional: Lighten the background */
  cursor: pointer;
}

.date-container input::-webkit-calendar-picker-indicator {
  pointer-events: auto; /* Re-enable pointer events for the calendar icon */
}

.order-stats {
  background-color: rgba(110, 137, 175, 0.0509803922);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 100%;
  transition: all 0.3s ease;
}

.order-stats__subtitle {
  font-weight: 600;
  font-size: 14px;
  color: #334257;
  margin-bottom: 0;
}
.latest {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 1rem; /* Adjust spacing between items if needed */
}

.date-container {
  flex-shrink: 0; /* Prevent the container from shrinking */
}
.order-stats__title {
  color: #0661cb;
  font-size: 18px;
  font-weight: 700;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
}

a {
  color: #377dff;
  text-decoration: none;
  background-color: transparent;
}

.order-stats__content img {
  width: 20px !important;
}

.order-stats__content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img_con {
  inset-block-start: 16px;
  inset-inline-end: 16px;
  inline-size: 35px;
  position: absolute;
}

.img_con-card {
  inline-size: 30px;
  padding: 3px;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  padding: 0.54688rem 1.875rem 0.54688rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  vertical-align: middle;
  background: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
}

.down-main {
  margin-top: 100px;
}

.custom-control-label,
.custom-select {
  cursor: pointer;
}

.card-header {
  border-bottom: 1px solid #f4f4f4;
  box-shadow: 0px 3px 3px rgba(51, 66, 87, 0.05);
}

.custom-select:focus {
  border-color: rgba(55, 125, 255, 0.4);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.dash-container {
  width: 100%;
  background-color: #f9f9fb;
  overflow: auto;
  overflow-x: hidden;
}

.grid-card {
  box-shadow: none;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px solid rgba(110, 137, 175, 0.05);
}

.orders-count {
  border-radius: 5px;
  font-size: 12px;
  line-height: 1;
  padding: 4px 10px;
  white-space: nowrap !important;
  position: relative !important;
  color: #0095ff !important;
}

.orders-count::before {
  content: "";
  position: absolute;
  border: 1px solid #0195ff;
  font-size: 12px;
  border-radius: 5px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
}

.basic-box-shadow:hover {
  box-shadow: 2px 2px 15px rgba(7, 59, 116, 0.15);
}
.basic-box-shadow {
  transition: all 0.3s ease;
  box-shadow: 1px 1px 5px rgba(71, 149, 233, 0.15);
}

.txtusername {
  text-align: center;
}
.responsive-username {
  display: inline-block;
 
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.4; 
}

@media (max-width: 1280px) {
  .responsive-username {
    font-size: 0.875rem; 
     max-width: 15ch;
  }
}

@media (max-width: 576px) {
  .responsive-username {
    font-size: 0.75rem; 
  }
}

.avatar-bordered {
  border: 1px solid rgba(7, 59, 116, 0.3);
}

.line--limit-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fz-12 {
  font-size: 12px !important;
}

.rating-color {
  color: rgb(240, 111, 0) !important;
}

.tio-star {
  color: #0095ff !important;
}

.custom-star-color {
  color: gold;
}

.img_star {
  height: 15px;
}
