.side_head {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: 20%;
  padding-top: 50px;
  overflow: auto;
  box-sizing: border-box;
  padding: 30px;
  box-shadow: 0px 0.3px 0.9px var(--bg-shadow), 0px 1.6px 3.6px var(--bg-shadow);
  background-color: whitesmoke;
}

.side_head::-webkit-scrollbar {
  width: 10px;
}

.side_head::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.side_head::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.side_head::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dash {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
  padding: 2px;
  border-radius: 5px;
  margin: 0;
}

.dash-space {
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  justify-content: space-between;
}

.sidebar-crct:hover {
  background-color: #ddd;
  border-radius: 10px;
}
.back {
  background-color: #8f8ad1;
}
.dash_img {
  font-size: 20px;
  color: rgb(92, 88, 88);
}
.selected {
  color: rgb(18, 147, 252);
}

.dropdown {
  font-size: 20px;
  cursor: pointer;
}

.dropdown_content {
  display: flex;
  flex-direction: column;
  align-items: first baseline;
  justify-content: center;
  padding: 10px 0px 10px 35px;
  transition: opacity 1.3s ease;
}

.dropdown_content.active {
  display: block;
  opacity: 1;
}

.dropdown_content > p {
  font-size: 12px;
  cursor: pointer;
}

.dropdown_content > p:hover {
  color: rgb(18, 147, 252);
  transform: translateX(5px);
}

.falcon_img {
  width: 70px;
  display: flex;
  align-items: center;
}

.sidebar-crct {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  transition: all 0.7s ease-in-out;
  cursor: pointer;
  margin-top: 5px;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.panel {
  padding: 0 18px;
  background-color: white;
  min-height: 0;
  overflow: hidden;
  transition: min-height 0.7s ease-out;
}
.sidebar-crct:hover .dash svg,
.sidebar-crct:hover .dash,
.sidebar-crct:hover svg {
}
.active1 {
  background-color: #ddd;
  border-radius: 10px;
}


@media (max-width:1308px) {
  .side_head{
   
    overflow-x: hidden;
  }
  
  /* .head_head{
    width: 80%;
    margin-left: 19.5%;
  } */
}
@media (max-width:1100px){
  .side_head{
    padding: 2px;
  }
}