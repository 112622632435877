.form-container {
  display: flex;
}

.form-column {
  flex: 1;
  margin-right: 20px;
}
.main-container {
  background-color: #F9F9FB;
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
  overflow: auto;
  position: relative;
}
.ql-snow {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-outline-primary.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.btn-outline-primary.inactive {
  background-color: white;
  color: #007bff;
  border-color: #007bff;
}

span{
  color: rgb(0, 0, 0);
}
.save-bt{
  display: flex;
  justify-content: end;
  width: 30%;
  margin-top: 30px;
}
.ql-snow .ql-toolbar {
  border-bottom: 1px solid #ccc;
}

.ql-snow .ql-editor {
  min-height: 200px;
}

.type-line {
  display: flex;
  margin: 5px;
  gap: 50px;
}

.varient-inputs {
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.input-group {
  flex: 1 1 calc(33.333% - 10px);
  margin-right: 10px;
}

.input-group:last-child {
  margin-right: 0;
}

.form-control {
  width: 70%;
  margin-bottom: 5px;
}
.form-select{
  width: 70%;

}

.button-end {
  margin-left: auto;
}
.Product-settings {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.input-group {
  margin-bottom: 15px;
}



.btn-group-toggle {
  display: flex;
  flex-direction: row;
}
.form-new {
}
.pr-info {
  display: flex;
  gap: 30px;
}
.pr-name {
  display: flex;
  flex-direction: column;
}
.add {
  cursor: pointer;
}
.product-variant {
  display: flex;
  gap: 10px;
}
.add-variant {
  display: flex;
  justify-content: space-between;
}
.radio-buttons input[type="radio"] {
  display: none;
  
}

.radio-buttons label {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.radio-buttons input[type="radio"] + label {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
}

.radio-buttons input[type="radio"]:checked + label {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}
.btn-group .btn {
  width: 80px; /* Consistent width for buttons */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0; /* Add padding for equal height */
  font-size: 14px; /* Adjust font size */
}

.btn.active {
  background-color: #007bff; /* Highlight active buttons */
  color: #fff;
}

input[type="radio"] {
  display: none; /* Hide default radio button appearance */
}

input[type="radio"] + span {
  cursor: pointer;
}
.form-container2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.form-container2 .form-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-container2 .form-row label {
  margin-right: 10px;
  font-weight: bold;
}

.form-container2 .form-row select {
  width: 200px;
}
