.btn-danger {
    background-color: #dc3545; /* Bootstrap's danger color */
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }

  .selected-images {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .selected-image {
    max-width: 100px;
    max-height: 100px;
    margin: 5px;
  }
  
  
  .btn-danger:hover {
    background-color: #c82333; /* Slightly darker red for hover effect */
  }
  
  .btn-danger:active {
    background-color: #bd2130; /* Even darker red for active (clicked) state */
  }
  
  .btn-danger:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); /* Red focus ring */
  }
  