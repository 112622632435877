.view_c{
    cursor: pointer;
}

.card.stock {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 20px;
    background-color: #fff;
    font-family: 'Arial', sans-serif;
    color: #333;
    transition: box-shadow 0.3s, transform 0.3s;
  
  }
  
  .card.stock:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }
  
  .card.stock p {
    margin: 0;
  }
  
  .card.stock p:first-child {
    font-weight: bold;
    color: #333;
  }
  
  .card.stock p:not(:first-child) {
    font-style: italic;
    color: #666;
  }
  