
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f8f9fa;
}

.main {
  height: 100vh;
  background-image: url("/src/Assets/image.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 15px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Welcome Text */
.welcome {
  font-size: 3rem; /* Adjust font size */
  font-weight: bold;
  background: linear-gradient(to bottom, #05c5ff 50%, #198754 100%);
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin-bottom: 20px;
}

/* Form Container */
.AppForm {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.AppFormLeft h1 {
  font-size: 2rem;
  color: #4b3535;
  margin-bottom: 10px;
}

/* Input Fields */
.input-text {
  width: 100%;
  padding: 12px 15px; /* Add padding for better spacing */
  font-size: 16px; /* Increased font size for better readability */
  border: 1px solid #ced4da;
  border-radius: 5px;
  color: #495057; /* Darker text color for readability */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.input-text:focus {
  border-color: #198754;
  outline: none;
}

.input-text::placeholder {
  font-size: 14px;
  color: #999;
}

/* Password Toggle */
.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #1d97d0;
}

/* Buttons */
.btn {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}

.btn-success {
  background-color: #37a279;
  border-color: #37a279;
  color: white;
}

.btn-success:hover {
  background-color: #2c8a60;
  border-color: #2c8a60;
}

/* Responsive Design */
@media (max-width: 768px) {
  .AppForm {
    width: 100%;
  }

  .welcome {
    font-size: 2.5rem;
  }
}
/* Text Input */


.input-text:focus {
  border-color: #198754; /* Highlight border on focus */
  outline: none;
  box-shadow: 0 0 5px rgba(25, 135, 84, 0.5); /* Subtle shadow for focus */
}

.input-text::placeholder {
  color: #adb5bd; /* Placeholder color */
  font-size: 14px; /* Match input styling */
}

/* Password Toggle Button */
.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.25rem;
  color: #6c757d;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.password-toggle-icon:hover {
  color: #198754; /* Change color on hover */
}

/* Submit Button */
.btn {
  display: inline-block;
  width: 100%; /* Make button fill container width */
  padding: 12px 15px;
  font-size: 16px; /* Larger, readable font */
  text-transform: uppercase;
  font-weight: bold;
  color: #fff; /* White text for contrast */
  background-color: #37a279; /* Default green button */
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.btn:hover {
  background-color: #2c8a60; /* Darker green on hover */
}

.btn:active {
  transform: scale(0.98); /* Subtle button press effect */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .input-text {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 10px; /* Adjust padding for smaller devices */
  }

  .btn {
    font-size: 14px; /* Adjust button size */
    padding: 10px; /* Adjust padding */
  }
}
/* .main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
} */

.login-container {
  display: flex;
  width: 80%;
  max-width: 1200px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.login-box {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-box {
  flex: 1;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.image-box img {
  max-width: 60%;
  max-height: 50%;
  object-fit: contain;
  margin:10px;;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.input-text {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.btn {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
}

.btn:hover {
  background-color: #0056b3;
}
