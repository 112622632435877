/* .container {
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  } */
  
  select,
  button {
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 16px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th,
  td {
    border: 1px solid #ccc;
    padding: 2px;
    text-align: left;
  }
  