.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
}

.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body p {
  margin: 10px 0;
}

.modal-footer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.btn {
  flex: 1;
  min-width: 120px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  border: none;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%;
  }

  .modal-title {
    font-size: 1.25rem;
  }

  .btn {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .btn {
    flex: 1;
    font-size: 0.8rem;
    min-width: 100px;
  }
}
.modal-body p {
  display: flex;
  justify-content: space-between;
  margin: 8px 0; /* Adjust spacing between rows */
}

.modal-body strong {
  flex: 0 0 150px; /* Fixed width for labels */
  text-align: left; /* Align text to the left */
  font-weight: bold;
}

.modal-body .value {
  flex: 1;
  text-align: left; /* Align value text */
  padding-left: 10px; /* Space between label and value */
}
.table-container {
  padding: 20px;
}

.table th, .table td {
  text-align: center;
  vertical-align: middle;
}

.btn-delete {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #007bff;
}

.btn-delete:hover {
  color: #0056b3;
}
