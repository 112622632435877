.more{
    border: none;
    color: white;
    background-color:rgb(104, 216, 216);
    padding: 6px 10px;
}
.h6{
    display: flex;
    align-items: center;
    flex-direction: column;
}