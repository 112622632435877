.toggle-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .toggle-group {
    display: flex;
  }
  
  .toggle-button {

    padding: 5px 70px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .toggle-button:hover {
    background-color: #f0f0f0;
  }
  
  .toggle-button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  
  .toggle-button:last-child {
    border-radius: 0 20px 20px 0;
  }
  .center-text {
    text-align: center;
  }