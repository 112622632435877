.od_items_69{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}
.status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    width: 100px; 
    text-align: center; 
    margin: 5px; 
  }
  
  .active-status {
    background-color: green;
    color: #fff;
  }
  
  .deactive-status {
    background-color: red;
    color: #fff;
  }
  