.save-category-button {
    background-color: #078f30;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-category-button:hover {
    background-color: #08b93d;
  }
