.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  transition: transform 0.3s;
}

.image-container:hover img {
  transform: scale(1.5);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #333;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
} */

.bold {
  font-weight: bold;
  color: #474747 !important;
}