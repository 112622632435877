.mod_h{
    display: flex;
   gap: 112px;
}
.r_1{
    display: flex;
    flex-direction: column;
}
.form-control-1{
    width: 119%;
    display: block;
 
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.mod_h1{
    display: flex;
    justify-content: flex-start;
}
.mod_head{
    display: flex;
    flex-direction: column;
    gap: 20px;
}